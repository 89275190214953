@import '../../assets/scss/_variables.scss';

.logo-container{
    z-index: -1;
    width: 50%;
    height: auto;
    position: absolute;
    top: 0;
    right: 5%;
    bottom: 0;
    left: auto;
    margin: auto;
    opacity: 1;

    @media screen and (max-width: 667px) {
        width: 50%;
        height: auto;
        bottom: 0;
        right: 0;
      }
      @media screen and (max-width: 1080px) {
        // transform: translateY(50%);
      }

    svg{
        width: 100%;        
        height: auto;
        bottom: 0;
    }
    .solid-logo{
        position: absolute;
        top: auto;
        right: auto;
        bottom: auto;
        left: 0;
        margin: auto;
        width: 100%;
        opacity: 0;
    }
    .solid-logo.full-opacity{
        opacity: 0.5;
        transition: opacity 2s ease-out;
        
        @media screen and (min-width: 1080px) {
            opacity: 1;
          }
    }
}

.svg-container{
    stroke: $primary-color;
    stroke-width: 10px;
}