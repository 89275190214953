@import './assets//scss/_variables.scss';
@import 'animate.css';
@import '~loaders.css/src/animations/line-scale.scss';

@import url('https://fonts.googleapis.com/css?family=Yatra+One');

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/helvetica-neue.woff2') format('woff2');
}
@font-face {
  font-family: 'La Belle Aurore';
  src: url('./assets/fonts/LaBelleAurore.woff2') format('woff2');
}
@font-face {
  font-family: 'Coolvetica';
  src: url('./assets/fonts/CoolveticaRg-Regular.woff2') format('woff2');
}

input,textarea{
  font-family: 'Helvetica Neue';
  font-weight: 800;
}

.loader-active{
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  animation: fadeOut 1s 1s;
  animation-fill-mode: forwards;

  @media screen and (max-width: 667px) {
    left: 25%;
  }
}

.line-scale > div {
  background-color: $primary-color;
}