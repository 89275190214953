@import '../../assets/scss/_variables.scss';

.portfolio-page{
    padding-left: 100px;
    padding-right: 50px;
    width: calc(100% - 150px);
    position: initial;
    height: 100%;
    overflow: auto;

    h1{
        font-size: 53px;
        font-family: 'Coolvetica';
        color: $primary-color;
        font-weight: 400;
        margin-top: 100px;
        margin-left: 100px;
        position: relative;
        margin-bottom: 40px;
        left: 10px;

        @media screen and (max-width: 667px) {
            font-size: 35px;
            }

        &::before{
            content: '<h1>';
            font-family: 'La Belle Aurore';
            color: $primary-color;
            font-size: 18px;
            position: absolute;
            margin-top: -10px;
            left: 15px;
            opacity: 0.6;
        }
        
        &::after{
            content: '</h1>';
            font-family: 'La Belle Aurore';
            color: $primary-color;
            font-size: 18px;
            position: absolute;
            margin-top: 38px;
            margin-left: 20px;
            animation: fadeIn 1s 1.7s backwards;
            opacity: 0.6;

            @media screen and (max-width: 667px) {
                margin-left: 0;
                margin-top: 28px;
            }
        }
    }

    .images-container {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        justify-content: center;
        padding-bottom: 100px;
    }

    .image-box {
        position: relative;
        flex: 1 1 20%;
        height: 400px;
        overflow: hidden;
        border-radius: 10px;
        max-width: calc(25% - 10px);

        .portfolio-image {
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .content {
            position: absolute;
            width: 100%;
            z-index: 3;
            padding: 10px 20px;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.2) 0,
                rgba(0, 0, 0, 1)
            );
            bottom: -70px;
        }

        .title {
            margin-bottom: 0;
            margin-top: 0;
            color: #fff;
            font-size: 24px;
            font-weight: 500;
            line-height: 24px;
        }

        .description {
            font-size: 14px;
            margin-bottom: 5px;
            margin-right: 10px;
            color: #fff;
            font-weight: 700;
        }

        .btn {
            margin-top: 30px;
            margin-bottom: 10px;
            padding: 0 23px;
            height: 40px;
            line-height: 34px;
            border: 2px solid $primary-color;
            border-radius: 4px;
            font-size: 14px;
            color: #fff;
            background: transparent;
            text-transform: uppercase;
            font-weight: 700;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            cursor: pointer;
        }

        .btn:hover {
            transform: translateY(-3px);
            background: $primary-color;
        }

        &:after {
            content: "";
            background: linear-gradient(180deg, $primary-color, #000);
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            opacity: 0;
        }

        &:hover:after {
            opacity: 0.85;
        }

        &:hover .content {
            bottom: 0;
            background: transparent;
        }
    
    }
}

@media screen and (max-width:667px){    
    .portfolio-page{
        h1{
            margin-left: 20px;
        }

        .image-box{
            height: 200px;
            max-width: calc(50% - 10px);
        }
        .btn {
            padding: 0;
        }
    }
}