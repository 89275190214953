@import '../../assets/scss/_variables.scss';

.page{
    width: 100%;
    height: 100%;
    position: absolute;
}

.top-tags{
    bottom: auto;
    top: 35px;
}

.tags{
    color: $primary-color;
    opacity: 0.6;
    position: absolute;
    bottom: 0;
    left: 120px;
    font-size: 18px;
    font-family: 'La Belle Aurore';
}

.bottom-tag-html{
    margin-left: -20px;
}

.container{
    width: 100%;
    will-change: contents;
    height: 90%;
    min-height: 566px;
    position: absolute;
    opacity: 0;
    top: 5%;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
}