@import '../../assets/scss/_variables.scss';

.about-page{
    .text-zone{
        position: absolute;                
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 35%;
        vertical-align: middle;
        display: table-cell;
        max-height: 90%;

        @media screen and (max-width: 667px) {
            left: 30%;
            width: 50%;
          }

          @media screen and (max-width: 1080px) {
            left: 30%;
            width: 50%;
          }

          @media screen and (max-height: 1000px) {
            // Enable scroll for smaller height screens
           overflow: scroll;
           scrollbar-width: none; /* Firefox */
           -ms-overflow-style: none;  /* IE 10+ */

           &::-webkit-scrollbar {
               background: transparent; /* Chrome/Safari/Webkit */
               width: 0px;
           }
          }           

          h1{
            font-size: 53px;
            font-family: 'Coolvetica';
            color: $primary-color;
            font-weight: 400;
            margin-top: 10px;
            position: relative;
            margin-bottom: 40px;
            left: 10px;

            @media screen and (max-width: 667px) {
                font-size: 26px;
              }

            &::before{
                content: '<h1>';
                font-family: 'La Belle Aurore';
                color: $primary-color;
                font-size: 18px;
                position: absolute;
                margin-top: -10px;
                left: 15px;
                opacity: 0.6;

                @media screen and (max-width: 667px) {
                    left: 0;
                  }
            }
            
            &::after{
                content: '</h1>';
                font-family: 'La Belle Aurore';
                color: $primary-color;
                font-size: 18px;
                position: absolute;
                margin-top: 38px;
                margin-left: 20px;
                animation: fadeIn 1s 1.7s backwards;
                opacity: 0.6;

                @media screen and (max-width: 667px) {
                    margin-left: 0;
                    margin-top: 28px;
                  }
            }
        }

        p{
            font-size: 15px;
            color: #fff;
            font-family: sans-serif;
            font-weight: 500;
            min-width: fit-content;
            animation: pulse 1s;

            &:nth-of-type(1){
                animation-delay: 1.1s;
            }
            &:nth-of-type(2){
                animation-delay: 1.2s;
            }
            &:nth-of-type(3){
                animation-delay: 1.3s;
            }
        }
        .get-in-touch{
            color: $primary-color;
            font-family: 'Coolvetica';
            font-size: 16px;
            font-weight: 600;
            text-decoration: underline;
        }
    }
}