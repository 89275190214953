@import '../../assets/scss/_variables.scss';

// Glitch effect
.glitch, .glitch:hover{ text-decoration: none;color: white;}
.glitch {
  font-family: 'Yatra One', cursive;
  color: white;
  // font-size: 250%;
  position: relative;
  // top: 20px;
  width: 200px;
  margin: 0 auto;
}
@keyframes noise-anim {
  0% {
    clip: rect(74px, 9999px, 68px, 0);
  }
  5% {
    clip: rect(9px, 9999px, 33px, 0);
  }
  10% {
    clip: rect(53px, 9999px, 97px, 0);
  }
  15% {
    clip: rect(97px, 9999px, 35px, 0);
  }
  20% {
    clip: rect(36px, 9999px, 39px, 0);
  }
  25% {
    clip: rect(79px, 9999px, 17px, 0);
  }
  30% {
    clip: rect(60px, 9999px, 36px, 0);
  }
  35% {
    clip: rect(49px, 9999px, 20px, 0);
  }
  40% {
    clip: rect(62px, 9999px, 16px, 0);
  }
  45% {
    clip: rect(61px, 9999px, 6px, 0);
  }
  50% {
    clip: rect(71px, 9999px, 60px, 0);
  }
  55% {
    clip: rect(91px, 9999px, 12px, 0);
  }
  60% {
    clip: rect(44px, 9999px, 10px, 0);
  }
  65% {
    clip: rect(65px, 9999px, 69px, 0);
  }
  70% {
    clip: rect(47px, 9999px, 68px, 0);
  }
  75% {
    clip: rect(18px, 9999px, 40px, 0);
  }
  80% {
    clip: rect(40px, 9999px, 69px, 0);
  }
  85% {
    clip: rect(6px, 9999px, 35px, 0);
  }
  90% {
    clip: rect(70px, 9999px, 60px, 0);
  }
  95% {
    clip: rect(60px, 9999px, 45px, 0);
  }
  100% {
    clip: rect(36px, 9999px, 26px, 0);
  }
}
.glitch:after {
  content: attr(data-text);
  position: absolute;
  left: 2px;
  text-shadow: -1px 0 red;
  top: 0;
  color: white;
  background: black;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim 2s infinite linear alternate-reverse;
}
@keyframes noise-anim-2 {
  0% {
    clip: rect(82px, 9999px, 81px, 0);
  }
  5% {
    clip: rect(37px, 9999px, 78px, 0);
  }
  10% {
    clip: rect(4px, 9999px, 47px, 0);
  }
  15% {
    clip: rect(97px, 9999px, 44px, 0);
  }
  20% {
    clip: rect(23px, 9999px, 34px, 0);
  }
  25% {
    clip: rect(66px, 9999px, 89px, 0);
  }
  30% {
    clip: rect(58px, 9999px, 15px, 0);
  }
  35% {
    clip: rect(94px, 9999px, 64px, 0);
  }
  40% {
    clip: rect(10px, 9999px, 59px, 0);
  }
  45% {
    clip: rect(86px, 9999px, 67px, 0);
  }
  50% {
    clip: rect(22px, 9999px, 74px, 0);
  }
  55% {
    clip: rect(87px, 9999px, 14px, 0);
  }
  60% {
    clip: rect(53px, 9999px, 75px, 0);
  }
  65% {
    clip: rect(70px, 9999px, 42px, 0);
  }
  70% {
    clip: rect(12px, 9999px, 59px, 0);
  }
  75% {
    clip: rect(92px, 9999px, 61px, 0);
  }
  80% {
    clip: rect(41px, 9999px, 98px, 0);
  }
  85% {
    clip: rect(33px, 9999px, 91px, 0);
  }
  90% {
    clip: rect(94px, 9999px, 26px, 0);
  }
  95% {
    clip: rect(19px, 9999px, 57px, 0);
  }
  100% {
    clip: rect(43px, 9999px, 60px, 0);
  }
}
.glitch:before {
  content: attr(data-text);
  position: absolute;
  left: -2px;
  text-shadow: 1px 0 blue;
  top: 0;
  color: white;
  background: black;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim-2 3s infinite linear alternate-reverse;
}
// Glitch effect END
h1{
  color: #fff;
  font-size: 53px;
  margin: 0;
  font-family: 'Coolvetica';
  font-weight: 400;

  &::before{
    content: '<h1>';
    font-family: 'La Belle Aurore';
    color: $primary-color;
    font-size: 18px;
    position: absolute;
    margin-top: -40px;
    left: 15px;
    opacity: 0.6;
  }

  &::after{
    content: '</h1>';
    font-family: 'La Belle Aurore';
    color: $primary-color;
    font-size: 18px;
    position: absolute;
    margin-top: 18px;
    margin-left: 20px;
    animation: fadeIn 1s 1.7s backwards;
    opacity: 0.6;
  }
}

h2{
  color: #8d8d8d;
  margin-top: 20px;
  font-weight: 400;
  font-size: 11px;
  font-family: sans-serif;
  letter-spacing: 3px;
  animation: fadeIn 1s 1.8s backwards;
}

.home-page{
  .text-zone{
    position: absolute;
    left: 20%;
    top: 50%;
    transform: translateY(-50%);
    width: 60%;
    max-height: 90%;
    
    @media screen and (max-width: 667px) {
      left: 30%;
    }
  }
}

.flat-button{
  color: $primary-color;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 4px;
  font-family: sans-serif;
  text-decoration: none;
  padding: 10px 18px;
  border: 1px solid $primary-color;
  margin-top: 25px;
  float: left;
  animation: fadeIn 1s 1.8s backwards;
  white-space: nowrap;

  &:hover{
    background: $primary-color;
    color: #333;
  }
}